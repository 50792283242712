import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { NgxAudioPlayerModule } from 'ngx-audio-player';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExpisodeComponent } from './expisode/expisode.component';
import { NoghteSareKhatComponent } from './programs/noghte-sare-khat/noghte-sare-khat.component';
import { HomepageComponent } from './homepage/homepage.component';
import { PeikeYazdanComponent } from './programs/peike-yazdan/peike-yazdan.component';
import { SoltaneIranComponent } from './programs/soltane-iran/soltane-iran.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatDividerModule } from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import { AftabeBineshComponent } from './programs/aftabe-binesh/aftabe-binesh.component';

@NgModule({
  declarations: [
    AppComponent,
    ExpisodeComponent,
    NoghteSareKhatComponent,
    HomepageComponent,
    PeikeYazdanComponent,
    SoltaneIranComponent,
    FooterComponent,
    AftabeBineshComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // NgxAudioPlayerModule,
    BrowserAnimationsModule,
    MatDividerModule,
    MatCardModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
