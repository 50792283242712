import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noghte-sare-khat',
  templateUrl: './noghte-sare-khat.component.html',
  styleUrls: ['./noghte-sare-khat.component.scss']
})
export class NoghteSareKhatComponent implements OnInit {
  noghteSareKhat = {
    summary: 'گاهي بايد لحظه­ اي از همه­ چيز دست بكشيم و اندكي بيانديشيم. تأمّل كنيم. ببينيم در كجاي جاده­ زندگي­مان ايستاده­ ايم، و تهِ اين جاده به كجا مي­رسد؟ گاهي بايد داستانِ زندگي­مان را ورق بزنيم، ببينيم كجاي ماجراييم ... آن وقت است كه شايد دريابيم كه بايد بر اين داستان نقطه ­اي بگذاريم و برويم سر خط؛ تا بتوانيم از نو شروع كنيم. نقطه ­سرخط مجالي است براي همين تأمّل كوتاه. نقطه ­سرخط را هر پنج­شنبه، در مجله­ جوانان از راديو پيام دوست بشنويد. تهیه و اجرای نوید توکلی.',
    fileList: [
      {
        label: ' نقطه سرخط - خنده و هنر بر هر درد بی‌درمان دواست',
        description: 'در نقطه‌سرخط این هفته، دوتا یادداشت خواهیم داشت از فهیم عطار و در این دو یادداشت درباره‌ خنده و ترس و امید و قرنطینه و تنهایی و هنر و دلتنگی و مهربانی و مراقبت صحبت خواهیم کرد.',

        fileName: 'nqThsrkhT-khndhwhnrbrhrdrdbydrmndwst.mp3'
      },
      {
        label: ' نقطه سرخط - شهروندی جهانی - قسمت ۳',
        description: 'در نقطه‌سرخط این هفته، در سومین و آخرین بخش از یادداشت «گفتمان، هویت و شهروندی جهانی»، راه‌های علمی تبدیل  هویت جهانی به اقدامات اجتماعی واقعی و اصلاحات نهادی را برمی‌شماریم تا یاد بگیریم چگونه می‌توانیم اصل انتزاعی یگانگی را کاملا عملی کنیم',

        fileName: 'nqThsrkhT-shhrwndyjhny-qsmt3.mp3'
      },
      {
        label: ' نقطه سرخط - شهروندی جهانی - قسمت ۲',

        description: 'در نقطه‌سرخط این هفته، یادداشت «گفتمان، هویت و شهروندی جهانی» را پی می‌گیریم و در دومین بخش از این یادداشت، درباره‌ هویت فراگیر جهانی صحبت خواهیم کرد و ثابت می‌کنیم که چرا شهروندی جهانی آرمانی انتزاعی و دوردست نیست. همچنین استدلال افراد شکاک را نیز بررسی خواهیم کرد',

        fileName: 'nqThsrkhT-shhrwndyjhny-qsmt2.mp3'
      },
      {
        label: ' نقطه سرخط - شهروندی جهانی قسمت ۱',

        description: 'در نقطه‌سرخط این هفته، یادداشتی را آغاز خواهیم کرد با عنوان «گفتمان، هویت و شهروندی جهانی». در نخستین بخش از این یادداشت، درباره‌ مفهوم شهروندی جهانی و دلایل اهمیت آن در جهان امروز صحبت خواهیم کرد.',

        fileName: 'nqThsrkhT-shhrwndyjhnyqsmt1.mp3'
      },
      {
        label: ' نقطه سرخط - بهاریه',

        description: 'این هفته، در نخستین نقطه‌سرخط سال جدید، بخش‌هایی از یک بهاریه دلچسب و نوستالژیک را خواهید شنید؛ بهاریه‌ای از مشهورترین بهاریه‌ نویس معاصر ایران: پرویز دوایی.',

        fileName: 'nqThsrkhT-bhryh.mp3'
      },
      {
        label: ' نقطه سرخط - سه شنبه پلاس',

        description: 'این هفته، در آخرین نقطه‌سرخط سال ۹۸، یک یادداشت جالب خواهیم داشت با موضوع و عنوانی جالب: سه‌شنبه پلاس. علاوه بر اون، یک مهمان ویژه هم خواهیم داشت؛ دوست عزیزی که کارشناس سه‌شنبه‌هاست و می‌خوایم درباره‌ سه‌شنبه پلاس باهاش صحبت کنیم.',

        fileName: 'nqThsrkhT-shshnbhpls.mp3'
      },
      {
        label: 'نقطه سرخط – آدم‌های معمولی و چگالی زندگی',

        description: 'این هفته در نقطه‌سرخط، دو تا یادداشت خواهید شنید از دو نویسنده‌ مختلف درباره‌ زندگی واقعی آدم‌های معمولی و چگالی زندگی. دو یادداشتی که شاید بتوان آن‌ها را دو روی یک سکه دانست.\n\n',

        fileName: 'nqThsrkhT-admhymmwlywchglyzndgy.mp3'
      },
      {
        label: ' نقطه سرخط - مثلث کارپمن و راه‌های خروج از آن ق۶ (پایانی)',

        description: ' این هفته در ششمین و آخرین نقطه‌سرخطی که به مبحث مثلث کارپمن و مثلث تد اختصاص داره، پس از مرور آن چه که در هفته‌های گذشته شنیده‌اید، با یک مثال دیگر از رفتار مهاتما گاندی این مبحث را به پایان خواهیم رساند',

        fileName: 'nqThsrkhT-mthlthkhrpmnwrhhykhrwjzanq6(pyny).mp3'
      },
      {
        label: ' نقطه سرخط - مثلث کارپمن و راه‌های خروج از آن ق۵',

        description: 'در چهار هفته‌ی اخیر در نقطه‌سرخط، درباره‌ی مثلث کارپمن و مثلث تد صحبت کردیم. این هفته در پنجمین قسمت، با یک مثال موفق از خروج از چرخه‌ی کارپمن در تاریخ معاصر ایران این مبحث را پی می‌گیریم.   ',

        fileName: 'nqThsrkhT-mthlthkhrpmnwrhhykhrwjzanq5.mp3'
      },
      {
        label: ' نقطه سرخط - مثلث کارپمن و راه‌های خروج از آن ق۴',

        description: 'طی سه هفته‌ گذشته در نقطه‌ سرخط، درباره‌ مثلث کارپمن و با مثلث تد به عنوان روشی برای خروج کارپمن صحبت کردیم. این هفته با یک مثال از تاریخ امریکا این مبحث را پی می‌گیریم و به هویت کاذب نیز می‌پردازیم.    ',

        fileName: 'nqThsrkhT-mthlthkhrpmnwrhhykhrwjzanq4.mp3'
      },
      {
        label: ' نقطه سرخط - مثلث کارپمن و راه‌های خروج از آن ق۳',

        description: 'طی دو هفته‌ گذشته در نقطه‌سرخط، درباره‌ مثلث کارپمن و یک مثال تاریخی موفق از نحوه‌ خروج از این چرخه‌ معیوب صحبت کردیم. این هفته در سومین قسمت، با مثلث تد به عنوان روشی برای خروج از کارپمن در سطوح مختلف آشنا خواهید شد.   ',

        fileName: 'nqThsrkhT-mthlthkhrpmnwrhhykhrwjzanq3.mp3'
      },
      {
        label: ' نقطه سرخط - مثلث کارپمن و راه‌های خروج از آن ق۲',

        description: 'از هفته‌ گذشته در نقطه‌سرخط، مبحثی را شروع کردیم درباره‌ی مثلث کارپمن و راه‌های خروج از این  چرخه‌ معیوب. این هفته در دومین قسمت، با یک مثال تاریخی موفق از نحوه‌ خروج از کارپمن، این مبحث را پی می‌گیریم.',

        fileName: 'nqThsrkhT-mthlthkhrpmnwrhhykhrwjzanq2.mp3'
      },
      {
        label: ' نقطه سرخط - مثلث کارپمن و راه‌های خروج از آن ق۱',

        description: 'از این هفته در نقطه‌ سرخط، چند هفته‌ای درباره‌ی مثلث کارپمن و راه‌های خروج از این  چرخه‌ معیوب صحبت خواهیم کرد. این هفته در نخستین قسمت، با مثلث کارپمن و نقش‌های آن و ویژگی‌های هر نقش آشنا خواهید شد.',

        fileName: 'nqThsrkhT-mthlthkhrpmnwrhhykhrwjzanq1.mp3'
      },
      {
        label: ' نقطه سرخط - نیرنگ شادی ق۲',

        description: 'ین هفته در نقطه‌ سرخط، در بخش دوم و پایانی یادداشت «نیرنگ شادی»، به خاستگاه تاریخی روان‌شناسی مثبت و ایده‌ شادی فوق‌العاده خواهیم پرداخت، و نهایتا درباره‌ راه‌ حلی برای رهایی از این بازی دو سر باخت صحبت خواهیم کرد. ',

        fileName: 'nqThsrkhT-nyrngshdyq2.mp3'
      },
      {
        label: ' نقطه سرخط - نیرنگ شادی! ق۱ ',

        description: 'همیشه احساس می‌کنید زندگی چیزی کم دارد؟ حتی وقتی در حال تفریح یا سفر هستید به نظرتان می‌آید که به اندازه کافی تفریحات یا سفرتان هیجان‌انگیز نیست؟ «شادی» از نظرتان تجربه فوق‌العاده‌ای است که با این دل خوشکنک‌های روزمره به دست نمی‌آید؟ اگر چنین فکرهایی از سرتان می‌گذرد، در نقطه‌سرخط این هفته و هفته‌ آینده سعی می‌کنیم با نوع دیگری از شادی آشنا شویم که باعث غمگین‌تر شدن‌مان نشود',

        fileName: 'nqThsrkhT-nyrngshdyq1.mp3'
      },
      {
        label: 'نقطه سرخط - امید در اوج یأس',

        description: 'فیلم «پرواز بر فراز آشیانه‌ فاخته» ساخته‌ میلوش فورمن رو دیدید؟ فیلم «مه» فرنک دارابونت رو چطور؟ در نقطه‌سرخط این هفته، با نگاهی به دو سکانس قدرتمند از این دو فیلم، درباره‌ امید در اوج یأس و ناامیدی صحبت خواهیم کرد',

        fileName: 'nqThsrkhT-myddrwjys.mp3'
      },
      {
        label: 'نقطه سرخط – سه یادداشت',

        description: 'آیا «لطفاً گوسفند نباشید» رو خوندید؟ آیا تا حالا زیر بارون گیتار زدید؟ و آیا از جانی دپ در فیلم دزدان دریایی کارائیب خوشتون میاد؟ در نقطه‌سرخط این هفته، به این سوالات پاسخ نمی‌دیم! اما سه تا یادداشت خواهید شنید که بی‌ارتباط با این پرسش‌ها نیستند!\n\n',

        fileName: 'nqThsrkhT-shyddsht.mp3'
      },
      {
        label: 'نقطه سرخط - روز جهانی مهاجرت',

        description: 'در نقطه‌ سرخط این هفته، به مناسبت روز جهانی مهاجرت، دو یادداشت خواهید شنید درباره‌ مهاجرت از دو نویسنده ایرانی مهاجرت‎ کرده',

        fileName: 'nqThsrkhT-rwzjhnymhjrt.mp3'
      },
      {
        label: 'نقطه سرخط -  داستان',

        description: 'این هفته در نقطه‌ سرخط، بخش‌هایی از فصل نخست یک رمان جذاب فارسی را خواهید شنید؛ کتابی با عنوان «قهوه‌ سرد آقای نویسنده» اثر روزبه معین',

        fileName: 'nqThsrkhT-dstn.mp3'
      },
      {
        label: 'نقطه سرخط - اینترنت ق۴',

        description: 'سه هفته‌ نقطه‌ سرخط آبان، در نقطه‌ سرخط، به یادداشتی پرداختیم با عنوان «پیش از اینترنت چه کار می‌کردی؟» در نقطه‌ سرخط این هفته، دو مطلب دیگر خواهید شنید که با اشاره به یادداشت مذکور، به مقایسه‌ دنیای پیش و پس از اینترنت پرداخته‌اند',

        fileName: 'nqThsrkhT-yntrntq4.mp3'
      },
      {
        label: 'نقطه سرخط - حضرت عبدالبهاء',

        description: 'صحبت‌های استاد وحید خرسندی درباره‌ی حضرت عبدالبهاء، مهم‌ترین ویژگی ایشان، دلایل منحصربه‌فرد بودنشان در تاریخ بشر، و نفوذ کلام‌شان، در ویژه‌برنامه‌ی نقطه‌سرخط به مناسبت سالروز درگذشت حضرت عبدالبهاء',

        fileName: 'nqThsrkhT-HDrtbdlbh.mp3'
      },
      {
        label: 'نقطه سرخط - اینترنت ق۳',

        description: 'این هفته در نقطه‌ سرخط، در بخش سوم و پایانی یادداشت «قبل از اینترنت چه کار می‌کردی؟» به مقایسه‌ دنیای پیش و پس از ظهور اینترنت خواهیم پرداخت و این مبحث را به سرانجام خواهیم رساند',

        fileName: 'nqThsrkhT-yntrntq3.mp3'
      },
      {
        label: 'نقطه سرخط - اینترنت ق۲',

        description: '«چیزی نمانده تا زمانی که هیچ‌کس روی زمین یادش نیاید دنیا پیش از اینترنت چه شکلی بود. وقتی که این اتفاق بیافتد، چه چیز از دستمان می‌رود؟» این پرسشی است که هفته‌ گذشته، در نقطه‌ سرخط مطرح کردیم و این هفته نیز همان مبحث را پی می گیریم.',

        fileName: 'nqThsrkhT-yntrntq2.mp3'
      },
      {
        label: 'نقطه سرخط -اینترنت ق۱',

        description: 'چیزی نمانده تا زمانی که هیچ‌کس روی زمین یادش نیاید دنیا پیش از اینترنت چه شکلی بود. وقتی که این اتّفاق بیافتد، چه چیز از دستمان می‌رود؟ این پرسشی است که طی سه هفته، در نقطه‌ سرخط به آن خواهیم پرداخت',

        fileName: 'nqThsrkhT-yntrntq1.mp3'
      },
      {
        label: 'نقطه سرخط – فقر',

        description: 'به مناسبت روز جهانی ریشه‌کنی فقر، در نقطه‌سرخط این هفته با شنیدن بخشی از یک سخنرانی و مرور یک یادداشت به نقد دیدگاهی خواهیم پرداخت که «می‌خواهد ما را متقاعد کند که فقرا باید به خاطر فقیر بودن‌شان سرزنش شوند».\n\n',

        fileName: 'nqThsrkhT-fqr.mp3'
      },
      {
        label: 'نقطه سرخط – بهداشت روان',

        description: 'به مناسبت روز جهانی بهداشت روان، در نقطه‌سرخط این هفته دو دلنوشته خواهید شنید که موضوع و محتوای هرکدام می‌تواند ارتباطی مستقیم با بهداشت روان و سلامت روانی ما داشته باشد.\n\n',

        fileName: 'nqThsrkhT-bhdshtrwn.mp3'
      },
      {
        label: 'نقطه سرخط – دل‌شکستگی ق۲',

        description: 'هفته‌ی گذشته در نقطه‌سرخط، مکانیسم دفاعی بدن در مورد دلشکستگی را بررسی کردیم. این هفته در ادامه‌ی یادداشت هفته‌ی پیش درخواهیم یافت که برای درمان دلِ شکسته چه باید کرد.\n\n',

        fileName: 'nqThsrkhT-dlshkhstgyq2.mp3'
      },
      {
        label: 'نقطه سرخط – دل‌شکستگی ق۱',

        description: 'آیا می‌دانید وقتی رابطه‌ای به جدایی ختم می‌شود و دل‌تان می‌شکند، چه اتّفاقاتی در مغز و بدن می‌افتد؟ و آیا می‌دانید درمان دلشکستگی چیست؟ در نقطه‌سرخط این هفته و هفته‌ی آینده به پرسش‌هایی از این دست پاسخ خواهیم داد.\n\n',

        fileName: 'nqThsrkhT-dlshkhstgyq1.mp3'
      },
      {
        label: 'نقطه سرخط – همدلی ق۳',

        description: 'این پنجشنبه، سه‌گانه‌ی «همدلی» نقطه‌سرخط را به سرانجام می‌رسانیم. در سومین قسمت از یادداشت «موردی برای همدلی»، وضعیت کامل هم‌دلی را در چند رفتار خلاصه می‌کنیم تا به طور عملی یاد بگیری چطور هم‌دلانه رفتار کنیم.\n\n',

        fileName: 'nqThsrkhT-hmdlyq3.mp3'
      },
      {
        label: 'نقطه سرخط – همدلی ق۲',

        description: 'بخش دوم یادداشتی که از هفته قبل و به مناسبت روز جهانی نیکوکاری و خیریّه شروع کردیم به نام “موردی برای همدلی”\n\n',

        fileName: 'nqThsrkhT-hmdlyq2.mp3'
      },
      {
        label: 'نقطه سرخط – همدلی ق۱',

        description: 'به مناسبت روز جهانی خیریه و نیکوکاری، طی سه هفته در نقطه‌سرخط، به یادداشتی می‌پردازیم درباره‌ی «همدلی».\n\n',

        fileName: 'nqThsrkhT-hmdlyq1.mp3'
      },
      {
        label: 'نقطه سرخط – نوستالژی',

        description: 'نقطه‌سرخط این هفته تشکیل شده از: یک یادداشت نوستالژیک که به خاطرات مشترک دهه‌ شصتی‌ها می‌پردازد و یک پادکست با عنوان «دلتنگی».\n\n',

        fileName: 'nqThsrkhT-nwstljy.mp3'
      },
      {
        label: 'نقطه سرخط – عکس',

        description: 'یک یادداشت و یک پادکست هردو با محوریت «عکس»؛ به مناسبت روز جهانی عکاسی.\n\n',

        fileName: 'nqThsrkhT-khs.mp3'
      },
      {
        label: 'نقطه سرخط – گیلتی پلژر ق۲',

        description: 'در ادامه‌ نقطه‌ سرخط هفته‌ پیش، باز به موضوع «گیلتی پلژر» می‌پردازیم و در نهایت از زاویه‌ای جدید به آن نگاه می‎کنیم.\n\n',

        fileName: 'nqThsrkhT-gyltypljrq2.mp3'
      },
      {
        label: 'نقطه سرخط – گیلتی پلژر ق۱',

        description: 'طی دوهفته، در نقطه‌سرخط، قصد داریم به موضوع «گیلتی پلژر» بپردازیم: اوّل اینکه گیلتی پلژر چیست؟ سپس چرا پرداختن به آن جالب و مهم است؟ و بالاخره، چند مورد گیلتی پلژر جالب را با هم مرور می‌کنیم.\n\n',

        fileName: 'nqThsrkhT-gyltypljrq1.mp3'
      },
      {
        label: 'نقطه سرخط - مادر ',

        description: 'دلنوشته‌ای به نام «به همین سادگی» به مناسبت روز جهانی شیر مادر.',

        fileName: 'nqThsrkhT-mdr.mp3'
      },
      {
        label: 'نقطه سرخط – جرم چیست و مجرم کیست؟',

        description: 'تعریف شما از جرم چیست؟ آیا این تعریف در همه‌ کشورهای دنیا یکسان یا دست‌کم نزدیک به هم است؟ آیا جایی را سراغ ندارید که با ارائه‌ تعریف شخصی و سلیقه‌ای از جرم، بی‌گناهان را فقط و فقط به خاطر رفتار و افکار و عقاید متفاوت‌شان، مجرم شمارند؟ «به سلامتی محتوای مجرمانه» و «جمهوری مجرمان» دو یادداشتی هستند که در نقطه سرخط این هفته به این قبیل پرسش‌ها پاسخ می دهند.\n\n',

        fileName: 'nqThsrkhT-jrmchystwmjrmkhyst.mp3'
      },
      {
        label: 'نقطه سرخط – همسایه‌ها و حریم خصوصی ق۲',

        description: 'به نظر شما مرز بین بی‌اعتنایی به وضعیت همسایه‌ها و حریم خصوصی کجاست؟ یا به عبارت دقیق‌تر «وقتی صدای دعوای همسایه‌ها را می‌شنویم، باید کاری بکنیم؟» در نقطه‌سرخط این هفته بخش دوم و پایانی یادداشتی را خواهید شنید که به این مسئله می‌پردازد. مسئله‌ای که سؤال بسیاری از ساکنان شهرهای بزرگ و پرجمعیت است.\n\n',

        fileName: 'nqThsrkhT-hmsyhhwHrymkhSwSyq2.mp3'
      },
      {
        label: 'نقطه سرخط – همسایه ها و حریم خصوصی ق۱',

        description: 'به نظر شما مرز بین بی‌اعتنایی به وضعیت همسایه‌ها و حریم خصوصی کجاست؟ یا به عبارت دقیق‌تر «وقتی صدای دعوای همسایه‌ها را می‌شنویم، باید کاری بکنیم؟» در نقطه‌سرخط این هفته بخش اوّل یادداشتی را خواهید شنید که به این مسئله می‌پردازد. مسئله‌ای که سؤال بسیاری از ساکنان شهرهای بزرگ و پرجمعیت است.\n\n',

        fileName: 'nqThsrkhT-hmsyhhwHrymkhSwSyq1.mp3'
      },
      {
        label: 'نقطه سرخط – ماندن یا رفتن؟ ق۲',

        description: 'در نقطه‌ سرخط این هفته، ادامه‌ یادداشت «تحقق ایران در خارج از ایران، تحقق خارج از ایران در ایران» رو خواهید شنید که سعی می‌کنه برای یک پرسش همیشگی، پاسخ مناسب و معقولی پیدا کنه: بحث جاودانه‌ ماندن یا رفتن.\n\n',

        fileName: 'nqThsrkhT-mndnyrftnq2.mp3'
      },
      {
        label: 'نقطه سرخط – ماندن یا رفتن؟ ق۱',

        description: 'در نقطه‌ سرخط این هفته و هفته‌ آینده، یادداشتی رو خواهید شنید که سعی می‌کنه برای یک پرسش همیشگی، پاسخ مناسب و معقولی پیدا کنه: بحث جاودانه‌ ماندن یا رفتن.\n\n',

        fileName: 'nqThsrkhT-mndnyrftnq1.mp3'
      },
      {
        label: 'نقطه سرخط – مفید بودن',

        description: 'در نقطه‌سرخط این هفته، با مرور یادداشتی از «آقای مترجم»، به این مسئله خواهیم پرداخت که کارها و فعالیت های ما چه‌قدر مفید هستند یا به اصطلاح چه‌قدر به درد می‌خورند.\n\n',

        fileName: 'nqThsrkhT-mfydbwdn.mp3'
      },
      {
        label: 'نقطه سرخط – دو یادداشت',

        description: 'به نظر شما خنده‌دارترین بخش اجرای استندآپ کمدین‌ها چیست؟ و اینکه چه‌قدر با کمپین «احمق‌ها را معروف نکنیم» در فضای مجازی موافقید؟ دردو یادداشت شنیدنی و جالبی که در نقطه‌سرخط این هفته خواهید شنید، پاسخ این پرسش‌ها را خواهید یافت\n\n',

        fileName: 'nqThsrkhT-dwyddsht.mp3'
      },
      {
        label: 'نقطه سرخط – روان‌شناسی زرد!',

        description: 'در نقطه‌سرخط این هفته، با نگاهی به یادداشت «معجزه ای رخ می دهد؟» به آسیب-شناسی مقوله ای خواهیم پرداخت که یک جامعه‌شناس آن را «روان‌شناسی زرد» می‌نامد: گونه‌ای از روان‌شناسی که از علمِ روان‌شناسی تنها چند کلمه‌ی قلمبه‌‌سلمبه و چند نامِ پرآوازه به امانت می‌گیرد تا ظاهری علمی به مباحث خود دهد.\n\n',

        fileName: 'nqThsrkhT-rwnshnsyzrd.mp3'
      },
      {
        label: 'نقطه سرخط – قصّه ی «جیجو» ق۲ و آخر',

        description: 'در نقطه‌سرخط این هفته بخش دوم و پایانی قصه‌ی «جیجو» رو می شنوید از احسان عبدی‌پور؛ قصّه ای عجیب درباره‌ی رفاقت احسان با یک دزد! در این بخش، احسان ضمن توضیح اینکه چرا با جیجو رفیق است، درباره‌ی مفهوم و اهمیت رفاقت به نکات جالبی اشاره می‌کند.\n\n',

        fileName: 'nqThsrkhT-qSWhy-jyjw-q2wakhr.mp3'
      },
      {
        label: 'نقطه سرخط – قصّه ی «جیجو» ق۱',

        description: 'در نقطه‌سرخط این هفته بخش اوّل قصّه ی «جیجو» رو می شنوید از احسان عبدی‌پور؛ قصّه ی عجیبی که با این جمله آغاز می شود: «ما یک رفیق داریم که دزد است!»\n\n',

        fileName: 'nqThsrkhT-qSWhy-jyjw-q1.mp3'
      },
      {
        label: 'نقطه سرخط – مسئله‌ فقر',

        description: 'نقطه‌ سرخط این هفته را با یادداشتی از مهدی یزدانی‌ خرم شروع می کنیم درباره‌ «فقر» و در ادامه نیم نگاهی می اندازیم به دیدگاه دیانت بهائی به مسئله‌ فقر، جایگاه فقرا در جامعه، و تعلیم مهم تعدیل معیشت.\n\n',

        fileName: 'nqThsrkhT-msylhfqr.mp3'
      },
      {
        label: 'نقطه سرخط – اهمال‌‌کاری ق۳',

        description: 'به نظر شما، چرا ما اهمال‌کاری می‌کنیم؟ چرا کار امروز رو به فردا می‌سپاریم با اینکه می‌دونیم این رفتار به ضررمون تموم می‌شه؟ این هفته، در ادامه‌ نقطه‌ سرخط دو هفته پیش، بخش سوم یادداشتی رو می‌شنوید که از زوایای مختلف به مسئله‌ی اهمال‌کاری می‌پردازه و برای رفع این مشکل، راهکارهای مختلفی هم ارائه می‌ده.\n\n',

        fileName: 'nqThsrkhT-hmlkhryq3.mp3'
      },
      {
        label: 'نقطه سرخط – اهمال‌‌کاری ق۲',

        description: 'به نظر شما، چرا ما اهمال‌کاری می‌کنیم؟ چرا کار امروز رو به فردا می‌سپاریم با اینکه می‌دونیم این رفتار به ضررمون تموم می‌شه؟ این هفته، در ادامه‌ نقطه‌ سرخط هفته‌ پیش، بخش دوم یادداشتی رو می‌شنوید که از زوایای مختلف به مسئله‌ اهمال‌کاری می‌پردازه و برای رفع این مشکل، راهکارهای مختلفی هم ارائه می‌ده.\n\n',

        fileName: 'nqThsrkhT-hmlkhryq2.mp3'
      },
      {
        label: 'نقطه سرخط – اهمال‌‌کاری ق۱',

        description: 'به نظر شما، چرا ما اهمال‌کاری می‌کنیم؟ چرا کار امروز رو به فردا می‌سپاریم با اینکه می‌دونیم این رفتار به ضررمون تموم می‌شه؟ در نقطه‌سرخط این هفته، بخش اول یادداشتی رو می‌شنوید که از زوایای مختلف به مساله اهمال‌کاری می‌پردازه و برای رفع این مشکل، راهکارهای مختلفی هم ارائه می‌ده.\n\n',

        fileName: 'nqThsrkhT-hmlkhryq1.mp3'
      },
      {
        label: 'نقطه سرخط – نوروز',

        description: 'نخستین نقطه‌ سرخط سال ۹۸، یک نقطه‌ سرخط ویژه‌ی نوروزیه که به دوتا یادداشت نوروزی اختصاص داره که به سبکی متفاوت، سال نو رو تبریک می‌گن.\n\n',

        fileName: 'nqThsrkhT-nwrwz.mp3'
      },
      {
        label: 'نقطه سرخط – خانه‌ تکانی و یک یادداشت دیگر',

        description: 'در آخرین نقطه‌ سرخط سال ۱۳۹۷ خورشیدی، دو تا یادداشت خودمانی خواهیم داشت: اولی تشویقمان می‌کند به تعریف‌ کردن از یکدیگر و دومی از خانه‌‌ تکانی و سال جدید حرف می‌زند.\n\n',

        fileName: 'nqThsrkhT-khnhtkhnywykhyddshtdygr.mp3'
      },
      {
        label: 'نقطه سرخط – بهروز بوچانی',

        description: 'در نقطه سرخط این هفته، درباره بهروز بوچانی حرف خواهیم زد و سخنرانی جالب و تکان دهنده این نویسنده کُرد ایرانی محبوس در جزیره مانوس را خواهید شنید.\n\n',

        fileName: 'nqThsrkhT-bhrwzbwchny.mp3'
      },
      {
        label: 'نقطه سرخط – خستگی تصمیم',

        description: 'در نقطه سرخط این هفته اولا متوجه خواهید شد که خستگی تصمیم چیست؟ و ثانیا چگونه از آن در امان باشیم؟\n\n',

        fileName: 'nqThsrkhT-khstgytSmym.mp3'
      },
      {
        label: 'نقطه سرخط – دلنوشته',

        description: '«پیاده‌روی در کنار درختان توت» و «درد» عنوان دوتا دلنوشته است به قلم پویان اوحدی، که در نقطه‌ سرخط این هفته خواهید شنید.\n\n',

        fileName: 'nqThsrkhT-dlnwshth.mp3'
      },
      {
        label: 'نقطه سرخط – آسیب شناسی به اشتراک‌گذاری تصاویر در شبکه‌های اجتماعی ق۲',

        description: 'در بخش دوم یادداشت «وقتی به‌ جای تجربه‌ کردنِ زندگی از آن عکس می‌گیریم، چه چیزی را از دست می‌دهیم؟»، در ادامه آسیب شناسی عادت روزافزون به اشتراک‌گذاری عکس و ویدیو در شبکه‌های اجتماعی، از تأثیرات منفی این کار بر تمرکز، خلاقیت و حس همدلی صحبت خواهیم کرد.\n\n',

        fileName: 'nqThsrkhT-asybshnsybhshtrkhgdhrytSwyrdrshbkhhhyjtmyq2.mp3'
      },
      {
        label: 'نقطه سرخط – آسیب شناسی به اشتراک‌گذاری تصاویر در شبکه‌های اجتماعی ۱',

        description: 'در نقطه سرخط این هفته، بخش اول یادداشتی را خواهید شنید که عادت روزافزون به اشتراک گذاری عکس و ویدئو در شبکه های اجتماعی را مورد بررسی قرار می دهد، انگیزه های روان شناختی این اقدام را تحلیل می کند و به آسیب های شخصی و اجتماعی آن نیز می پردازد.\n\n',

        fileName: 'nqThsrkhT-asybshnsybhshtrkhgdhrytSwyrdrshbkhhhyjtmy1.mp3'
      },
      {
        label: 'نقطه سرخط – دو یادداشت درباره ارزش واژه‌ها و لزوم اشتباه کردن',

        description: 'در نقطه سرخط این هفته، دو یادداشت خواهید شنید از آنالی اکبری که اولی هشداری است که ما با تظاهر کردن‌های بی دلیل ارزش واژه‌ها را از بین نبریم؛ و دومی مطلبی است در رثای تجربه و اشتباه کردن.\n\n',

        fileName: 'nqThsrkhT-dwyddshtdrbrhrzshwjhhwlzwmshtbhkhrdn.mp3'
      },
      {
        label: 'نقطه سرخط – جهان جعلی رسانه‌ها',

        description: 'یادداشتی که در نقطه سرخط این هفته خواهید شنید، با انتقاد از توجه بیش از حد رسانه ها به هنرپیشه ها و دیگر سلبریتی ها، به این مساله می پردازد که «مشکل رسانه عمیق‌تر از اخبار جعلی است؛ کاری که آن‌ها می‌کنند ارائه اخبار درباره جهانی جعلی است!»\n\n',

        fileName: 'nqThsrkhT-jhnjlyrsnhh.mp3'
      },
      {
        label: 'نقطه سرخط – آداب خردمندی',

        description: 'در نقطه سرخط این هفته، با آداب خردمندی آشنا می‌شویم در ۲۰ سرفصل، و در می‌یابیم که فرق خردمند با هوشمند چیست و اصولا خردمند کیست.\n\n',

        fileName: 'nqThsrkhT-adbkhrdmndy.mp3'
      },
      {
        label: 'نقطه سرخط – دلایل شکست و راهکارهای خروج از آن',

        description: 'در نقطه سرخط این هفته، به صحبت‌های یک درمانگر گوش می‌دیم که درباره تله‌هایی که باعث می‌شن هی شکست بخوریم و راه‌های خروج از این شکست‌ها حرف می زنه، و تأکید می‌کنه که خودمون باید به خودمون کمک کنیم چون «رابین هودی وجود نداره»!\n\n',

        fileName: 'nqThsrkhT-dlylshkhstwrhkhrhykhrwjzan.mp3'
      }
    ],
    filePath: 'media/noghtesarekhat/'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
