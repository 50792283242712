import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-peike-yazdan',
  templateUrl: './peike-yazdan.component.html',
  styleUrls: ['./peike-yazdan.component.scss']
})
export class PeikeYazdanComponent implements OnInit {

  peikeYazdan = {
    fileList: [
      { label: '(پیک یزدان –۷۰– اصول اعتقادی آئین بهائی۱ (وجود و توحید الوهیّت', fileName: 'PeykeYazdan_070-64.mp3' },
      { label: '(پیک یزدان –۷۱– اصول اعتقادی آئین بهائی۲ (رحمانیّت انسانی', fileName: 'PeykeYazdan_071-64.mp3' },
      { label: '(پیک یزدان –۷۲– اصول اعتقادی آئین بهائی ۳ (جاودانگی حیات انسانی', fileName: 'PeykeYazdan_072-64.mp3' },
      { label: '(پیک یزدان –۷۳– اصول اعتقادی آئین بهائی ۴ (تجرّد و بقای روح انسانی', fileName: 'PeykeYazdan_073-64.mp3' }
    ],
    filePath: 'media/PeikeYazdan/'
  };

  constructor() { }

  ngOnInit(): void {
  }

}
