import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aftabe-binesh',
  templateUrl: './aftabe-binesh.component.html',
  styleUrls: ['./aftabe-binesh.component.scss']
})
export class AftabeBineshComponent implements OnInit {
  aftabeBinesh = {
    summary: 'در برنامه آفتاب بینش به معرفی مختصر بعضی کتب بهائی خواهیم پرداخت. یعنی آثار حضرت باب، حضرت بهاءالله، حضرت عبدالبهاء، حضرت ولی امرالله و نیز بعضی آثار دیگر اندیشمندان بهائی. شنبه ها از رادیو پیام دوست',
    fileList: [
      { label: 'معرفی کتاب مستطاب اقدس', fileName: 'AftabeBinesh072519b_64k.mp3' },
      { label: 'هفت وادی', fileName: 'AftabeBinesh080819b_64k.mp3' },
      { label: 'چهار وادی', fileName: 'AftabeBinesh082219b_64k.mp3' },
      { label: 'مفاوضات', fileName: 'AftabeBinesh080119b_64k.mp3' },
      { label: 'مجموعه مناجات‌های حضرت عبدالبهاء', fileName: 'AftabeBinesh081519b_64k.mp3' },
      { label: 'کلمات مکنونه', fileName: 'AftabeBinesh090519b_64k.mp3' },
      { label: 'در شناسائی آئین بهائی', fileName: 'AftabeBinesh082919b_64k.mp3' },
      { label: 'تاریخ نبیل ق۱', fileName: 'AftabeBinesh091219b_64k.mp3' },
      { label: 'تاریخ نبیل ق۲', fileName: 'AftabeBinesh091919b_64k.mp3' },
      { label: 'کتاب ایقان', fileName: 'AftabeBinesh092619b_64k.mp3' }

    ],
    filePath: 'media/AftabeBinesh/'
  };
  constructor() { }

  ngOnInit(): void {
  }

}
