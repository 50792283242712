import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Howl, Howler } from 'howler';

@Component({
  selector: 'app-expisode',
  templateUrl: './expisode.component.html',
  styleUrls: ['./expisode.component.scss']
})
export class ExpisodeComponent implements OnInit {
  @Input() media: any;
  @Output() imPlaying = new EventEmitter<boolean>();

  audio = {
    object: new Howl({ src: [] }),
    isPlaying: false,
    current: ''
  };
  constructor() {
  }

  ngOnInit(): void {

  }
  public playAudio(src: string) {
    const currentFile = `${this.media.filePath}${src}`;
    if (currentFile !== this.audio.current) {
      if (this.audio.isPlaying) {
        this.audio.object.pause();
      }
      this.audio.isPlaying = false;
    }

    if (!this.audio.isPlaying) {

      if (currentFile === this.audio.current) {
        this.audio.object.play();
        this.audio.isPlaying = true;
      } else {
        this.audio.object = new Howl({
          src: [currentFile],
          html5: true, // Force to HTML5 so that the audio can stream in (best for large files).
        });
        // this.audio.object.load();
        this.audio.object.play();
        this.audio.isPlaying = true;
        this.audio.current = currentFile;
      }
    } else {
      this.audio.object.pause();
      this.audio.isPlaying = false;
    }
    this.imPlaying.emit(this.audio.isPlaying);
  }

  public isPlaying(fileName: string) {
    const passedIn = `${this.media.filePath}${fileName}`;
    if (passedIn === this.audio.current && this.audio.isPlaying) {
      return true;
    }
    else {
      return false;
    }
  }

  public getFilePath(src: string): string {
    return `${this.media.filePath}${src}`;
  }
  public stop() {
    if (this.audio.isPlaying) {
      this.audio.object.pause();
    }
    this.audio.isPlaying = false;
  }
}
