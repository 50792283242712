import { Component } from '@angular/core';
import { ElementSchemaRegistry } from '@angular/compiler';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'library';



  fullProgram = {
    fileList: [
      { label: 'برنامه کامل ۲۴ فروردین ۱۳۹۹', fileName: '1-24-1399.mp3' },
      { label: 'برنامه کامل ۲۵ فروردین ۱۳۹۹', fileName: '1-25-1399.mp3' },
      { label: 'برنامه کامل ۲۶ فروردین ۱۳۹۹', fileName: '1-26-1399-SilverTuesday.mp3' }
    ],
    filePath: 'media/fullProgram/'
  };



//   public noghteSareKhat(){
//     <div class="content" role="main">

  


//   <div class="row">
//     <div class="col-sm-1"></div>
//     <div class="col-sm-12 col-md-5 col-lg-5">
//       <!-- Soltaneh Iran -->
//       <app-expisode [media]="toTheKingOfIran" (imPlaying)="full.stop()" #king></app-expisode>
//     </div>
//     <div class="col-sm-12 col-md-6 col-lg-6">
//       <!-- Peike Yazdan -->
//       <app-expisode [media]="peikeYazdan" (imPlaying)="king.stop()" #full></app-expisode>
//     </div>
//   </div>

// </div>
//   }
}
