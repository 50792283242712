import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoghteSareKhatComponent } from './programs/noghte-sare-khat/noghte-sare-khat.component';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { PeikeYazdanComponent } from './programs/peike-yazdan/peike-yazdan.component';
import { SoltaneIranComponent } from './programs/soltane-iran/soltane-iran.component';
import { AftabeBineshComponent } from './programs/aftabe-binesh/aftabe-binesh.component';


const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'noghtesarehkhat', component: NoghteSareKhatComponent },
  { path: 'peikeyazdan', component: PeikeYazdanComponent },
  { path: 'soltan', component: SoltaneIranComponent },
  { path: 'aftabebinesh', component: AftabeBineshComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
