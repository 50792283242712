<style type="text/css">
  #fullProgram {
    position: absolute;
    top: 80px;
    right: 200px;
    width: 300px;
    height: 100%;
  }

  #soltan {
    position: absolute;
    top: 80px;
    right: 510px;
    width: 500px;
    height: 100%;
  }
</style>

<nav class="navbar navbar-dark bg-primary">
  <span class="navbar-text">
    <button class="button btn btn-info btn-lg"  routerLink="/noghtesarehkhat">نقطه سر خط</button>
    -
    <button class="button btn btn-info btn-lg"  routerLink="/peikeyazdan">پیک یزدان</button>
    -
    <button class="button btn btn-info btn-lg"  routerLink="/soltan">سلطان ایران</button>
    -
    <button class="button btn btn-info btn-lg"  routerLink="/aftabebinesh">آفتاب بینش</button>
  </span>
  <span class="navbar-brand mb-0 h1" routerLink="/">کتابخانه صوتی آثار</span>
</nav>
<br />


<router-outlet></router-outlet>
<app-footer></app-footer>
