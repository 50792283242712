import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soltane-iran',
  templateUrl: './soltane-iran.component.html',
  styleUrls: ['./soltane-iran.component.scss']
})
export class SoltaneIranComponent implements OnInit {
  toTheKingOfIran = {
    summary: 'حضرت بهاءالله پیامبر دیانت بهائی پس از اظهار امر، نامه و الواحی خطاب به پادشاهان عالم صادر فرمودند از جمله خطاب به ناصرالدین شاه قاجار. این لوح، لوح سلطان نام دارد. در این برنامه نوید توکلی در گفت و گو با جناب استاد وحید خرسندی به لوح سلطان خواهند پرداخت.',
    fileList: [
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت بیست هشتم', fileName: 'BePadeshaheIranPart28_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت بیست هفتم', fileName: 'BePadeshaheIranPart27_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت بیست ششم', fileName: 'BePadeshaheIranPart26_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت بیست پنجم', fileName: 'BePadeshaheIranPart25_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت بیست چهارم', fileName: 'BePadeshaheIranPart24_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت بیست سوم', fileName: 'BePadeshaheIranPart23_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت بیست دوم', fileName: 'BePadeshaheIranPart22_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت بیست یکم', fileName: 'BePadeshaheIranPart21_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت بیستم', fileName: 'BePadeshaheIranPart20_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت نوزدهم', fileName: 'BePadeshaheIranPart19_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت هژدهم', fileName: 'BePadeshaheIranPart18_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت هفدهم', fileName: 'BePadeshaheIranPart17_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت شانزدهم', fileName: 'BePadeshaheIranPart16_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت پانزدهم', fileName: 'BePadeshaheIranPart15_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت چهاردهم', fileName: 'BePadeshaheIranPart14_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت سیزدهم', fileName: 'BePadeshaheIranPart13_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت دوازدهم', fileName: 'BePadeshaheIranPart12_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت یازدهم', fileName: 'BePadeshaheIranPart11_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت دهم', fileName: 'BePadeshaheIranPart10_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت نهم', fileName: 'BePadeshaheIranPart9_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت هشتم', fileName: 'BePadeshaheIranPart8_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت هفتم', fileName: 'BePadeshaheIranPart7_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت ششم', fileName: 'BePadeshaheIranPart6_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت پنجم ', fileName: 'BePadeshaheIranPart5_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت چهارم', fileName: 'BePadeshaheIranPart4_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت سوم', fileName: 'BePadeshaheIranPart3_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت دوم', fileName: 'BePadeshaheIranPart2_24k.mp3' },
      { label: 'به-پادشاه-ایران-لوح-سلطان - قسمت اول', fileName: 'BePadeshaheIranPart1_24k.mp3' }
    ],
    filePath: 'media/toTheKingOfIran/lowBandwidth/'
  };
  constructor() { }

  ngOnInit(): void {
  }

}
