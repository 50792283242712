


 <div *ngIf="!!media.summary">
  <div class="row">
    <div class="col-lg-2 col-sm-1"></div>
    <div class="col-lg-8 col-sm-10 summary">

       <h2 dir="rtl"> {{media.summary}}</h2>

    </div>
    <div class="col-lg-2 col-sm-1"></div>
  </div>
  <br />
  <br />
</div>
<div class="row">
  <div class="col-sm-1 col-lg-1"></div>
  <div class="col-sm-10 col-lg-10 col-md-10">
    <div *ngFor="let media of media.fileList">

      <!-- <audio id="main-player" src="getFilePath(media.fileName)" controls></audio> -->
      <!-- <button class="button btn btn-primary btn-lg" (click)="playAudio(media.fileName)">{{media.label}}</button>
      <span class="blinking" *ngIf="isPlaying(media.fileName)">در حال پخش</span>
      <hr /> -->
      <mat-card class="audio-card">
        <mat-card-header>
          <div mat-card-avatar class="audio-header-image"></div>
          <mat-card-title>{{media.label}}</mat-card-title>
          <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <audio preload="none" controls="true" src="{{getFilePath(media.fileName)}}" style="width: 100%;"></audio>
        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
      </mat-card>
      

      
      <!-- <mat-basic-audio-player [audioUrl]="getFilePath(media.fileName)" [title]="media.label" [autoPlay]="false" muted="muted"
      [displayTitle]="true" [displayVolumeControls]="true"></mat-basic-audio-player> -->

      <!-- <span>{{media.label}}</span>-->
      <br/> 

       <mat-divider></mat-divider> 
      </div>
        
  </div>
  <div class="col-sm-1 col-lg-1"></div>
</div> 


